import { AdditionalRequestState, IdObject, Meta, ReferenceIds } from "../dataTypes";

export const appSliceName = "app";

interface Thumbnail {
  qUrl: string;
  qTitle?: string;
}

export interface ResponseApp {
  qDocId: string;
  qDocName?: string;
  qLastReloadTime: string;
  qMeta: Meta;
  qThumbnail: Thumbnail;
  qTitle: string;
}

export interface App extends ResponseApp, AdditionalRequestState, IdObject {
  sheets?: ReferenceIds;
}
