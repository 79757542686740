import { Response } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder, miniSerializeError } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { REQUEST_STATE } from "../../dataTypes";
import { setError } from "../../dialog/dialogSlice";
import createAsyncThunk from "../../utils/createAsyncThunk";
import { sessionSliceName, SessionState } from "../dataTypes";

const REPEAT = 6;
const SLICE_START = 2;
const SLICE_END = 18;

const getCookie = async (dispatch: AppDispatch, { JWT }: { JWT: string }) => {
  const xrfKey = Math.random().toString().repeat(REPEAT).slice(SLICE_START, SLICE_END);

  const url = api.qlik.getCookie(xrfKey);

  try {
    const response = await axios.get(url.toString, {
      headers: {
        "X-Qlik-xrfkey": xrfKey,
        Authorization: `Bearer ${JWT}`,
      },
      withCredentials: true,
    });

    return new Response(HttpStatusCodes.OK, REQUEST_STATE.FULFILLED, response);
  } catch (e) {
    if (e instanceof AxiosError) {
      dispatch(setError({ error: miniSerializeError(e), value: e.response?.statusText }));

      return new Response(
        e.response?.status ?? HttpStatusCodes.INTERNAL_SERVER_ERROR,
        e.response?.statusText ?? e.message,
        e,
      );
    } else {
      return new Response(HttpStatusCodes.INTERNAL_SERVER_ERROR, REQUEST_STATE.REJECTED, e as string);
    }
  }
};

export const fetchCookie = createAsyncThunk<undefined, { JWT: string }>(sessionSliceName + "/fetchCookie", getCookie);

export const addFetchCookieReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(fetchCookie.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchCookie.fulfilled, state => {
    state.isInitialized = true;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchCookie.rejected, (state, action) => {
    state.isInitialized = false;

    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
