import { Dictionary } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { FulfilledUpdateMethod, RequestState, REQUEST_STATE, UseFetchRef, UseFetchRefs } from "../../dataTypes";
import { useAppDispatch, useAppSelector } from "../../hooks";
import buildId from "../../utils/buildId";
import mergeRefAndStateEntities from "../../utils/mergeRefAndStateEntities";
import shouldResetRefsOnEmptyState from "../../utils/shouldResetRefsOnEmptyState";
import { selectAppEntities } from "../appSelectors";
import { App } from "../dataTypes";
import { fetchSheets } from "../reducers/fetchSheets";

interface Refs extends UseFetchRefs {
  [id: string]: (UseFetchRef & { sheets: { requestState?: RequestState } }) | undefined;
}

let refs: Refs;
const resetRefsOnEmptyState: { [appId: string]: boolean } = {};

const mapApps = (apps: Dictionary<App>) => {
  return Object.entries(apps).reduce<Refs>((result, [id, app]) => {
    result[id] = app
      ? {
          sheets: { requestState: app.sheets?.requestState },
          requestState: app.requestState,
        }
      : undefined;

    return result;
  }, {});
};

const useFetchSheets = () => {
  const dispatch = useAppDispatch();

  const apps = useAppSelector(selectAppEntities);

  if ((refs as UseFetchRefs | undefined) === undefined) refs = mapApps(apps);

  useEffect(() => {
    refs = mergeRefAndStateEntities(refs, mapApps(apps), shouldResetRefsOnEmptyState(resetRefsOnEmptyState));
  }, [apps]);

  const loadSheets = useCallback(
    async ({
      appId,
      fulfilledUpdateMethod,
    }: {
      appId: string | undefined;
      fulfilledUpdateMethod: FulfilledUpdateMethod;
    }) => {
      if (appId) {
        const ref = refs[appId];

        if (ref?.sheets.requestState !== REQUEST_STATE.PENDING) {
          resetRefsOnEmptyState[appId] = false;
          refs[appId] = {
            sheets: { requestState: REQUEST_STATE.PENDING },
            requestState: REQUEST_STATE.PENDING,
          };

          const { payload } = await dispatch(fetchSheets({ appId, fulfilledUpdateMethod }));

          resetRefsOnEmptyState[appId] = true;

          if (Array.isArray(payload)) {
            return payload.map(({ qInfo: { qId } }) => buildId(appId, qId));
          }
        }
      }
    },
    [dispatch],
  );

  return loadSheets;
};

export default useFetchSheets;
