/* File has been copied */

import PropType from "prop-types";
import Spinner from "../../assets/images/spinner.gif";
import { useTranslate } from "../../language/i18n";
import styles from "./LoadingSpinner.module.scss";

const LoadingSpinner = ({ title }: { title?: string }) => {
  const translate = useTranslate();

  return (
    <figure className={styles.spinner}>
      <img src={Spinner} alt={title} className={styles.spinnerImage} />
      {title && <figcaption>{translate(title)}</figcaption>}
    </figure>
  );
};

LoadingSpinner.propTypes = {
  title: PropType.string,
};

export default LoadingSpinner;
