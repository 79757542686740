import { Response } from "@myloc/myloc-utils";
import { miniSerializeError } from "@reduxjs/toolkit";
import enigma from "enigma.js";
import { AppDispatch } from "../../app/store";
import HttpStatusCodes from "../../utils/HttpStatusCodes";
import { QlikApi, REQUEST_STATE } from "../dataTypes";
import { setError } from "../dialog/dialogSlice";

const sendWebSocketRequest = async <WebSocketActionResponse extends string | object | undefined>({
  schema,
  chosenUrl,
  webSocketAction,
  dispatch,
}: {
  schema: enigmaJS.IConfig["schema"];
  chosenUrl: string;
  webSocketAction: (qlikApi: QlikApi) => WebSocketActionResponse;
  dispatch: AppDispatch;
}): Promise<Response> => {
  try {
    const config: enigmaJS.IConfig = {
      schema,
      url: chosenUrl,
      createSocket: () => new WebSocket(chosenUrl),
    };

    const session = enigma.create(config);

    const qlikApi = await session.open<QlikApi>();

    const result = await webSocketAction(qlikApi);

    session.close();

    return new Response(HttpStatusCodes.OK, REQUEST_STATE.FULFILLED, result);
  } catch (error) {
    let message = "";

    if (error instanceof Error) {
      message = error.message;
    } else message = REQUEST_STATE.REJECTED;

    dispatch(setError({ error: miniSerializeError(error), value: message }));

    return new Response(HttpStatusCodes.INTERNAL_SERVER_ERROR, message, undefined);
  }
};

export default sendWebSocketRequest;
