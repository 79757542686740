import { RootState } from "../../app/rootReducer";
import { appAdapter } from "./appAdapter";
import { appDataName } from "../../app/dataTypes";
import { App, appSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][appSliceName];
const adapterSelectors = appAdapter.getSelectors(selectSliceState);

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;
export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectAppEntities = adapterSelectors.selectEntities;
export const selectApps = adapterSelectors.selectAll;
export const selectApp = (id: string | undefined) => (state: RootState) => adapterSelectors.selectById(state, id ?? "");
export const selectAppsById = (ids: string[] | undefined) => (state: RootState) =>
  (ids ?? []).reduce<App[]>((apps, id) => {
    const app = selectApp(id)(state);

    return app ? [...apps, app] : apps;
  }, []);
