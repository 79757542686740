import { Button } from "@myloc/myloc-gui";
import { useTranslate } from "../../language/i18n";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import styles from "./ErrorPage.module.scss";

const ErrorPage = () => {
  const translate = useTranslate();

  return (
    <div>
      <LoadingSpinner
        title={
          !navigator.onLine ? translate("INTERNET_CONNECTION_ERROR_DESCRIPTION") : translate("SOMETHING_WENT_WRONG")
        }
      />
      <div className={styles.container}>
        <Button onClick={() => window.location.reload()}>{translate("RELOAD")}</Button>
      </div>
    </div>
  );
};

export default ErrorPage;
