import { RootState } from "../../app/rootReducer";
import { sheetAdapter } from "./sheetAdapter";
import { Sheet, sheetSliceName } from "./dataTypes";
import { appDataName } from "../../app/dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][sheetSliceName];
const adapterSelectors = sheetAdapter.getSelectors(selectSliceState);

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;
export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectSheetEntities = adapterSelectors.selectEntities;
export const selectSheets = adapterSelectors.selectAll;
export const selectSheet = (id: string | undefined) => (state: RootState) =>
  adapterSelectors.selectById(state, id ?? "");
export const selectSheetsById = (ids: string[] | undefined) => (state: RootState) =>
  (ids ?? []).reduce<Sheet[]>((apps, id) => {
    const app = selectSheet(id)(state);

    return app ? [...apps, app] : apps;
  }, []);
