import { ActionReducerMapBuilder, AsyncThunkPayloadCreator, EntityState } from "@reduxjs/toolkit";
import { AppDispatch } from "../app/store";
import { ValueOf } from "../utils/dataTypes";
import HttpStatusCodes from "../utils/HttpStatusCodes";
import { ResponseApp } from "./app/dataTypes";
import { ResponseSheet } from "./sheet/dataTypes";

export interface RequestError {
  httpStatusCode: ValueOf<typeof HttpStatusCodes>;
  message: string;
}

export const REQUEST_STATE = {
  PENDING: "pending",
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
} as const;

export type RequestState = ValueOf<typeof REQUEST_STATE>;

export interface AdditionalRequestState {
  requestState?: RequestState;
  errorMessage?: string;
}

export interface UseFetchRef {
  requestState?: RequestState;
}

export interface UseFetchRefs {
  [id: string]: UseFetchRef | undefined;
}

export interface EntityActionReducerBuilderInterface<T, AdditionalState = unknown>
  extends ActionReducerMapBuilder<EntityState<T> & AdditionalRequestState & AdditionalState> {}

export type AsyncThunkRejectWithValue = Parameters<
  AsyncThunkPayloadCreator<any, any, { dispatch: AppDispatch; rejectValue: RequestError }>
>["1"]["rejectWithValue"];

export const FULFILLED_UPDATE_METHOD = {
  UPSERT_MANY: "UPSERT_MANY",
  SET_ALL: "SET_ALL",
} as const;

export type FulfilledUpdateMethod = ValueOf<typeof FULFILLED_UPDATE_METHOD>;

export const QUERY_PARAM = {
  JWT: "jwt",
} as const;

interface Stream {
  id: string;
  name: string;
}
interface Owner {
  id: string;
  name: string;
  userDirectory: string;
  userId: string;
}
export interface Meta {
  createdDate: string;
  modifiedDate: string;
  published: boolean;
  publishTime?: string;
  description?: string;
  stream?: Stream;
  owner?: Owner;
  title?: string;
}

export interface ReferenceIds extends AdditionalRequestState {
  ids: string[];
}

export interface IdObject {
  id: string;
}

export const ID_TYPE = {
  APP: "app",
  SHEET: "sheet",
} as const;

export const ID_SPLITTER = "¤";

export interface Data {
  rank: string;
}
interface OptionSheet {
  qTypes: string[];
  qIncludeSessionObjects: boolean;
  qData?: Data;
}
export interface QOptionsSheet {
  qOptions: OptionSheet;
}
interface QReturn {
  qType: string;
  qHandle: number;
  qGGenericType: string;
  qGenericId: string;
  getObjects: (options: QOptionsSheet) => Promise<ResponseSheet[]>;
}

export interface QlikApi extends enigmaJS.IGeneratedAPI {
  openDoc: (id: string) => Promise<QReturn>;
  getDocList: () => Promise<ResponseApp[]>;
}
