import { AdditionalRequestState, IdObject, Meta } from "../dataTypes";

export const sheetSliceName = "sheet";

interface Info {
  qId: string;
  qType: string;
}

interface Data {
  rank: number;
}
export interface ResponseSheet {
  qInfo: Info;
  qMeta: Meta;
  qData?: Data;
}

export interface Sheet extends ResponseSheet, AdditionalRequestState, IdObject {
  appId: string;
}
