/* File has been copied */

import i18n from "i18next";
import { initReactI18next, useTranslation as useReactTranslation } from "react-i18next";
import translationSV from "./sv/translation.json";
import translationEN from "./en/translation.json";
import formatSV from "./sv/formatSV.json";
import formatEN from "./en/formatEN.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEN,
        format: formatEN,
      },
      sv: {
        translation: translationSV,
        format: formatSV,
      },
    },
    lng: "sv",
    fallbackLng: "sv",

    interpolation: {
      escapeValue: false,
    },
  });

export function useTranslate() {
  return useReactTranslation("translation").t;
}

export function useFormat() {
  return useReactTranslation("format").t;
}

export default i18n;
