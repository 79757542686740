import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { sheetSliceName } from "./dataTypes";
import { addFetchSheetsForAppsReducers } from "./reducers/fetchSheetsForApp";
import { sheetAdapter } from "./sheetAdapter";

const slice = createSlice({
  name: sheetSliceName,
  initialState: sheetAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addFetchSheetsForAppsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
