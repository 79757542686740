import PropTypes from "prop-types";
import classNames from "classnames";
import { IconButton } from "@myloc/myloc-gui";
import { useState } from "react";
import { InfoCircle } from "../../../../assets/icons";
import { selectApp } from "../../../../features/app/appSelectors";
import { useAppSelector } from "../../../../features/hooks";
import { selectSheet } from "../../../../features/sheet/sheetSelectors";
import { getTimestamp } from "../../../../utils/getTimestamp";

import OnClickOutside from "../../../../utils/OnClickOutside";
import Label from "../../../Label/Label";
import styles from "./InfoButton.module.scss";
import Collapse from "../../../Collapse/Collapse";
import { useTranslate } from "../../../../language/i18n";

const InfoButton = ({ sheetId }: { sheetId: string | undefined }) => {
  const translate = useTranslate();
  const [infoVisible, setInfoVisible] = useState(false);

  const sheet = useAppSelector(selectSheet(sheetId));
  const app = useAppSelector(selectApp(sheet?.appId));

  return (
    <OnClickOutside call={() => setInfoVisible(false)}>
      <section className={classNames(styles.menuWrapper)}>
        <div className={styles.container}>
          <IconButton onClick={() => setInfoVisible(setInfoVisible => !setInfoVisible)}>
            <InfoCircle size={25} />
          </IconButton>
        </div>
        <Collapse visible={infoVisible} customCssClass={styles.submenuWrapper}>
          <div className={styles.menu}>
            <ul className={styles.list}>
              {sheet && app && (
                <div>
                  <Label label={translate("DATA_WAS_LOADED")} value={getTimestamp(app.qLastReloadTime)} inline />
                  <Label label={translate("PUBLISHED")} value={getTimestamp(sheet.qMeta.publishTime)} inline />
                </div>
              )}
            </ul>
          </div>
        </Collapse>
      </section>
    </OnClickOutside>
  );
};

InfoButton.propTypes = {
  sheetId: PropTypes.string,
};

export default InfoButton;
