import { combineReducers } from "@reduxjs/toolkit";
import appSlice from "../features/app/appSlice";
import sheetSlice from "../features/sheet/sheetSlice";
import { appSliceName } from "../features/app/dataTypes";
import { sheetSliceName } from "../features/sheet/dataTypes";

const appDataReducer = combineReducers({
  [appSliceName]: appSlice,
  [sheetSliceName]: sheetSlice,
});

export default appDataReducer;
