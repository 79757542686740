import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { useAppSelector } from "../../features/hooks";
import { selectIsInitialized } from "../../features/session/sessionSelectors";

import pages from "../../utils/pages";
import useInit from "../../utils/useInit";
import ErrorPage from "../ErrorPage/ErrorPage";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Sheet from "../Sheet/Sheet";

import UserFeedback from "../UserFeedback/UserFeedback";
import Header from "./Header/Header";

import styles from "./Site.module.scss";

const Site = () => {
  const isInitialized = useAppSelector(selectIsInitialized);

  useInit();

  if (!isInitialized) {
    return (
      <Router>
        <UserFeedback />

        {isInitialized === false ? <ErrorPage /> : <LoadingSpinner />}
      </Router>
    );
  }

  return (
    <Router>
      <Header />
      <main className={styles.main}>
        <Routes>
          <Route path={pages.SHEET.PATH} element={<Sheet />} />
        </Routes>
        <UserFeedback />
      </main>
    </Router>
  );
};

export default Site;
