import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { appAdapter } from "./appAdapter";
import { appSliceName } from "./dataTypes";
import { addFetchAppsReducers } from "./reducers/fetchApps";
import { addFetchSheetsReducers } from "./reducers/fetchSheets";

const slice = createSlice({
  name: appSliceName,
  initialState: appAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addFetchAppsReducers(builder);
    addFetchSheetsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
