import { AppDispatch } from "../../../app/store";
import {
  EntityActionReducerBuilderInterface,
  FulfilledUpdateMethod,
  FULFILLED_UPDATE_METHOD,
  REQUEST_STATE,
} from "../../dataTypes";
import { ResponseSheet } from "../../sheet/dataTypes";
import { App, appSliceName } from "../dataTypes";

import schema from "enigma.js/schemas/12.1306.0.json";
import buildId from "../../utils/buildId";
import { appAdapter } from "../appAdapter";
import sendWebSocketRequest from "../../utils/sendWebsocketRequest";
import getSheetsFromQlikApi from "../../sheet/utils/getSheetsFromQlikApi";
import createAsyncThunk from "../../utils/createAsyncThunk";

const getSheets = async (dispatch: AppDispatch, { appId }: { appId: string }) => {
  const domain = process.env.REACT_APP_API_INTELLIGENCE_DOMAIN ?? "";
  const urlAppId = `wss://${domain}/jwt/app/${appId}`;

  return await sendWebSocketRequest({
    webSocketAction: qlikApi => getSheetsFromQlikApi(qlikApi, appId),
    schema,
    chosenUrl: urlAppId,
    dispatch,
  });
};

export const fetchSheets = createAsyncThunk<
  ResponseSheet[],
  { appId: string; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(appSliceName + "/fetchSheets", getSheets);

export const addFetchSheetsReducers = (builder: EntityActionReducerBuilderInterface<App>) => {
  builder.addCase(fetchSheets.pending, (state, action) => {
    const {
      meta: {
        arg: { appId },
      },
    } = action;

    const app = state.entities[appId] ?? ({ id: appId } as App);

    if (!app.sheets) app.sheets = { ids: [] };

    app.sheets.errorMessage = undefined;
    app.sheets.requestState = REQUEST_STATE.PENDING;
    appAdapter.upsertOne(state, app);
  });
  builder.addCase(fetchSheets.fulfilled, (state, action) => {
    const {
      meta: {
        arg: { fulfilledUpdateMethod, appId },
      },
      payload,
    } = action;

    const app = state.entities[appId];

    if (app) {
      const sheets = app.sheets;

      if (sheets) {
        const ids = fulfilledUpdateMethod === FULFILLED_UPDATE_METHOD.SET_ALL ? [] : sheets.ids;

        for (const sheet of payload) {
          const mergedId = buildId(appId, sheet.qInfo.qId);

          if (!ids.includes(mergedId)) {
            ids.push(mergedId);
          }
        }

        sheets.ids = ids;
        sheets.requestState = REQUEST_STATE.FULFILLED;
      }
    }
  });
  builder.addCase(fetchSheets.rejected, (state, action) => {
    const app = state.entities[action.meta.arg.appId];

    if (app) {
      const sheets = app.sheets;

      if (sheets) {
        sheets.requestState = REQUEST_STATE.REJECTED;
        sheets.errorMessage = action.payload?.message;
      }
    }
  });
};
