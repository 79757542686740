import Site from "./components/Site/Site";
import { ErrorBoundary } from "@myloc/myloc-utils";
import { Provider } from "react-redux";
import store from "./app/store";

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Site />
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
