import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sessionSliceName, SessionState } from "./dataTypes";
import { addFetchCookieReducers } from "./reducers/fetchCookie";

const initialState: SessionState = {
  isInitialized: null,
  JWT: null,
};

const slice = createSlice({
  name: sessionSliceName,
  initialState,
  reducers: {
    setIsInitialized(state, action: PayloadAction<boolean>) {
      state.isInitialized = action.payload;
    },
    setJWT(state, action: PayloadAction<string>) {
      state.JWT = action.payload;
    },
  },
  extraReducers: builder => {
    addFetchCookieReducers(builder);
  },
});

const { actions, reducer } = slice;

export const { setIsInitialized, setJWT } = actions;

export default reducer;
