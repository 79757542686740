import { createAsyncThunk as createReduxAsyncThunk } from "@reduxjs/toolkit";
import { Response } from "@myloc/myloc-utils";
import { AppDispatch } from "../../app/store";
import { RequestError } from "../dataTypes";
import createAsyncThunkPayload from "./createAsyncThunkPayload";

const createAsyncThunk = <Returned = undefined, ThunkArg = undefined>(
  typePrefix: string,
  serviceRequest: (dispatch: AppDispatch, args: ThunkArg) => Promise<Response>,
) => {
  return createReduxAsyncThunk<Returned, ThunkArg, { dispatch: AppDispatch; rejectValue: RequestError }>(
    typePrefix,

    async (args, { dispatch, rejectWithValue }) => {
      return await createAsyncThunkPayload<Returned, ThunkArg>(serviceRequest, args, dispatch, rejectWithValue);
    },
  );
};

export default createAsyncThunk;
