/* File has been copied */

import SnackbarMessage from "./SnackbarMessage/SnackbarMessage";
import { useAppDispatch } from "../../features/hooks";
import { resetMessage } from "../../features/dialog/dialogSlice";
import { useCallback } from "react";

const UserFeedback = () => {
  const dispatch = useAppDispatch();

  const onReset = useCallback(() => dispatch(resetMessage()), [dispatch]);

  return <SnackbarMessage onReset={onReset} />;
};

export default UserFeedback;
