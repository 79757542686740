import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useTranslate } from "../../language/i18n";
import { QlikSheet, THEME } from "@myloc/myg-intelligence";
import styles from "./Sheet.module.scss";

const Sheet = ({ theme = THEME.MYLOC_THEME }: { theme?: string }) => {
  const translate = useTranslate();
  const params = useParams<"appId" | "sheetId">();

  if (!params.appId || !params.sheetId) return <p>{translate("APP_OR_SHEET_ID_MISSING")}</p>;

  return (
    <QlikSheet
      baseUrl={process.env.REACT_APP_API_DOMAIN ?? ""}
      appId={params.appId}
      sheetId={params.sheetId}
      theme={theme}
      customCssClass={styles.height}
    />
  );
};

Sheet.propTypes = {
  theme: PropTypes.oneOf(Object.values(THEME)),
};

export default Sheet;
