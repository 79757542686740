import { generatePath, Link, useLocation, useNavigate } from "react-router-dom";
import page from "../../../utils/pages";
import logo from "../../../assets/logos/Myloc-logga.png";

import styles from "./Header.module.scss";
import InfoButton from "./InfoButton/InfoButton";
import useGetAppsAndSheets from "./useGetAppsAndSheets";
import MenuContainer from "../MenuDropdown/MenuContainer";
import { useCallback, useEffect, useState } from "react";
import pages from "../../../utils/pages";
import buildId from "../../../features/utils/buildId";
import { useTranslate } from "../../../language/i18n";
import { ID_TYPE } from "../../../features/dataTypes";
import getId from "../../../features/utils/getId";

const getFirstSheet = (apps: ReturnType<typeof useGetAppsAndSheets>["apps"]) => {
  for (const app of apps) {
    if (app.items.length > 0) return app.items[0];
  }
};

const Header = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSheetId, setActiveSheetId] = useState<string>();
  const { apps } = useGetAppsAndSheets();

  const getTitle = () => {
    const defaultTitle = translate("CHOOSE_SHEET");

    if (!apps.length) return defaultTitle;

    const activeAppId = getId(ID_TYPE.APP, activeSheetId);
    const foundApp = apps.find(app => app.id === activeAppId);

    if (!foundApp) return defaultTitle;

    for (const sheet of foundApp.items) {
      if (sheet.id === activeSheetId) {
        return sheet.title;
      }
    }

    return defaultTitle;
  };

  const handleOnClick = (id: string) => {
    setActiveSheetId(id);
  };

  const setActiveSheetIfNotSet = useCallback(() => {
    if (!apps.length) return;

    if (location.pathname.includes("sheet")) {
      const split = location.pathname.split("/");
      const appId = split[split.length - 2];

      const foundApp = apps.find(app => app.id === appId);

      if (!foundApp) return;

      const sheetId = split[split.length - 1];
      const mergedId = buildId(appId, sheetId);
      const foundSheet = foundApp.items.find(sheet => sheet.id === mergedId);

      if (foundSheet) setActiveSheetId(mergedId);
    } else {
      const sheet = getFirstSheet(apps);

      if (!sheet) return;

      setActiveSheetId(sheet.id);

      navigate({
        pathname: generatePath(pages.SHEET.PATH, {
          appId: getId(ID_TYPE.APP, sheet.id),
          sheetId: getId(ID_TYPE.SHEET, sheet.id),
        }),
      });
    }
  }, [location.pathname, apps, navigate]);

  useEffect(() => {
    if (activeSheetId === undefined) {
      setActiveSheetIfNotSet();
    }
  }, [activeSheetId, setActiveSheetIfNotSet]);

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.logoContainer}>
          <figure>
            <Link to={page.HOME}>
              <img src={logo} alt="Myloc logo" className={styles.logo} />
            </Link>
          </figure>
          <div className={styles.menuContainer}>
            <InfoButton sheetId={activeSheetId} />
            {!!apps.length && (
              <MenuContainer
                list={apps}
                title={getTitle()}
                activeId={activeSheetId}
                handleOnClickMenuItem={handleOnClick}
                showSearch
              />
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
