import { useCallback, useEffect } from "react";
import { FulfilledUpdateMethod, RequestState, REQUEST_STATE } from "../../dataTypes";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectRequestState } from "../appSelectors";
import { fetchApps } from "../reducers/fetchApps";

let requestStateRef: RequestState | undefined;

const useFetchApps = () => {
  const dispatch = useAppDispatch();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadApps = useCallback(
    async ({ fulfilledUpdateMethod }: { fulfilledUpdateMethod: FulfilledUpdateMethod }) => {
      if (requestStateRef !== REQUEST_STATE.PENDING) {
        requestStateRef = REQUEST_STATE.PENDING;

        await dispatch(fetchApps({ fulfilledUpdateMethod }));
      }
    },
    [dispatch],
  );

  return loadApps;
};

export default useFetchApps;
