import { ValueOf } from "../../utils/dataTypes";
import { ID_TYPE } from "../dataTypes";
import splitId from "./splitId";

const getId = (type: ValueOf<typeof ID_TYPE>, id: string | undefined) => {
  if (!id) return;

  const split = splitId(id);

  if (split.length < 1) return;

  switch (type) {
    case ID_TYPE.APP:
      return split[0];
    case ID_TYPE.SHEET:
      return split[1];
  }
};

export default getId;
