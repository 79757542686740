import { useAppSelector } from "../features/hooks";
import { selectIsInitialized, selectJWT } from "../features/session/sessionSelectors";
import useFetchCookie from "../features/session/hooks/useFetchCookie";
import { useCallback, useEffect } from "react";
import { setJWT } from "../features/session/sessionSlice";
import { useDispatch } from "react-redux";
import { QUERY_PARAM } from "../features/dataTypes";
import storage from "./store/storage";

const CHECK_SESSION_INTERVAL = 120000;

const useGetJWT = async () => {
  const dispatch = useDispatch();

  const sessionInitialized = useAppSelector(selectIsInitialized);
  const JWT = useAppSelector(selectJWT);
  const { loadCookie } = useFetchCookie();

  const connect = useCallback(async () => {
    if (JWT) await loadCookie({ JWT });
  }, [loadCookie, JWT]);

  const fetchJWT = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const JWTParam = params.get(QUERY_PARAM.JWT);

    if (JWTParam !== null) {
      storage.saveItem(QUERY_PARAM.JWT, false, JWTParam);
      dispatch(setJWT(JWTParam));
    } else {
      const cachedJWT = storage.loadItem(QUERY_PARAM.JWT);

      if (cachedJWT != null) dispatch(setJWT(cachedJWT.toString()));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!JWT) fetchJWT();
  }, [JWT, fetchJWT]);

  useEffect(() => {
    if (!sessionInitialized && JWT) {
      connect();
    }
  }, [sessionInitialized, JWT, connect]);

  useEffect(() => {
    const checkSession = setInterval(async () => await connect(), CHECK_SESSION_INTERVAL);

    return () => clearInterval(checkSession);
  }, [connect]);
};

const useInit = () => {
  useGetJWT();
};

export default useInit;
