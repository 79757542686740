import PropTypes from "prop-types";

import { ListItem } from "../dataTypes";
import MenuItem from "../MenuItem/MenuItem";
import styles from "./MenuContainerRow.module.scss";

const MenuContainerRow = ({
  listItem,
  activeId,
  handleOnClickMenuItem,
}: {
  listItem: ListItem;
  activeId?: string;
  handleOnClickMenuItem: (id: string) => void;
}) => {
  if (!listItem.items.length) return <></>;

  return (
    <li className={styles.row}>
      <p className={styles.title}>{listItem.title}</p>
      {listItem.items.map(item => (
        <MenuItem key={item.id} item={item} isActive={activeId === item.id} handleOnClick={handleOnClickMenuItem} />
      ))}
    </li>
  );
};

MenuContainerRow.propTypes = {
  listItem: PropTypes.object.isRequired,
  activeId: PropTypes.string,
  handleOnClickMenuItem: PropTypes.func.isRequired,
};

export default MenuContainerRow;
