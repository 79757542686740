import PropTypes from "prop-types";

import { Check } from "@myloc/myloc-gui";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { Item } from "../dataTypes";
import styles from "./MenuItem.module.scss";

//Flytta till GUI när det är omgjort till typescript
const MenuItem = ({
  item,
  isActive,
  handleOnClick,
}: {
  item: Item;
  isActive: boolean;
  handleOnClick: (id: string) => void;
}) => {
  return (
    <div className={classNames(styles.item, isActive && styles.active)}>
      <Link to={{ pathname: item.path, search: item.searchParams ?? "" }} onClick={() => handleOnClick(item.id)}>
        <div className={isActive ? styles.title : ""}>
          {item.title}
          {isActive && (
            <span className={styles.check}>
              <Check size={"20"} />
            </span>
          )}
        </div>
      </Link>
    </div>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

export default MenuItem;
