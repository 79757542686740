import { AppDispatch } from "../../../app/store";
import {
  EntityActionReducerBuilderInterface,
  FulfilledUpdateMethod,
  FULFILLED_UPDATE_METHOD,
  REQUEST_STATE,
} from "../../dataTypes";
import { appAdapter } from "../appAdapter";
import { App, appSliceName, ResponseApp } from "../dataTypes";

import schema from "enigma.js/schemas/12.1306.0.json";
import sendWebSocketRequest from "../../utils/sendWebsocketRequest";
import getAppsFromQlikApi from "../utils/getAppsFromQlikApi";
import createAsyncThunk from "../../utils/createAsyncThunk";

const mapFulfilledAppReponse = (app: ResponseApp): App => {
  return {
    ...app,
    id: app.qDocId,
    requestState: REQUEST_STATE.FULFILLED,
    errorMessage: undefined,
  };
};

const getApps = async (dispatch: AppDispatch) => {
  const domain = process.env.REACT_APP_API_INTELLIGENCE_DOMAIN ?? "";
  const urlEngineData = `wss://${domain}/jwt//app/engineData`;

  return await sendWebSocketRequest({
    webSocketAction: getAppsFromQlikApi,
    schema,
    chosenUrl: urlEngineData,
    dispatch,
  });
};

export const fetchApps = createAsyncThunk<ResponseApp[], { fulfilledUpdateMethod: FulfilledUpdateMethod }>(
  appSliceName + "/fetchApps",
  getApps,
);

export const addFetchAppsReducers = (builder: EntityActionReducerBuilderInterface<App>) => {
  builder.addCase(fetchApps.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchApps.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const apps = payload.map(mapFulfilledAppReponse);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        appAdapter.setAll(state, apps);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        appAdapter.upsertMany(state, apps);
        break;
    }

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchApps.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
