import { configureStore } from "@reduxjs/toolkit";
import { fetchCookie } from "../features/session/reducers/fetchCookie";
import rootReducer from "./rootReducer";

const ignoredActions: string[] = [fetchCookie.fulfilled.type];

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions,
      },
    }),
});

export type AppDispatch = typeof store.dispatch;

export default store;
