import { QOptionsSheet, QlikApi } from "../../dataTypes";

const getSheetsFromQlikApi = async (qlikApi: QlikApi, appId: string) => {
  const qApp = await qlikApi.openDoc(appId);

  const qOptionsSheet: QOptionsSheet = {
    qOptions: {
      qTypes: ["sheet"],
      qData: {
        rank: "/rank",
      },
      qIncludeSessionObjects: false,
    },
  };

  const sheets = await qApp.getObjects(qOptionsSheet);

  return sheets;
};

export default getSheetsFromQlikApi;
