import { fetchSheets } from "../../app/reducers/fetchSheets";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { ResponseSheet, Sheet } from "../dataTypes";
import { sheetAdapter } from "../sheetAdapter";
import buildId from "../../utils/buildId";

const mapFulfilledSheetObject = (sheet: ResponseSheet, appId: string): Sheet => {
  const mergedId = buildId(appId, sheet.qInfo.qId);

  return { ...sheet, id: mergedId, appId, requestState: REQUEST_STATE.FULFILLED, errorMessage: undefined };
};

export const addFetchSheetsForAppsReducers = (builder: EntityActionReducerBuilderInterface<Sheet>) => {
  builder.addCase(fetchSheets.fulfilled, (state, action) => {
    const {
      meta: {
        arg: { appId },
      },
      payload,
    } = action;

    if (payload.length) {
      sheetAdapter.upsertMany(
        state,
        payload.map(sheet => mapFulfilledSheetObject(sheet, appId)),
      );
    }
  });
};
