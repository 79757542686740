import { useCallback, useEffect, useRef, useState } from "react";
import { generatePath } from "react-router-dom";
import { App } from "../../../features/app/dataTypes";
import useFetchSheets from "../../../features/app/hooks/useFetchSheets";
import useLazyGetApps from "../../../features/app/hooks/useLazyGetApps";
import { FULFILLED_UPDATE_METHOD } from "../../../features/dataTypes";
import { useAppSelector } from "../../../features/hooks";
import { Sheet } from "../../../features/sheet/dataTypes";
import { selectSheetEntities } from "../../../features/sheet/sheetSelectors";
import { RequireSelected } from "../../../utils/dataTypes";

import pages from "../../../utils/pages";
import MenuContainer from "../MenuDropdown/MenuContainer";

type MenuList = Parameters<typeof MenuContainer>["0"]["list"];
type ListItem = MenuList[number];
type Item = ListItem["items"][number] & {
  rank: number;
};
interface SheetListItem extends ListItem {
  items: Item[];
}
type Items = SheetListItem["items"];

const mapSheetToItem = (sheet: Sheet): Item => ({
  id: sheet.id,
  title: sheet.qMeta.title ?? "",
  rank: sheet.qData?.rank ?? 0,
  path: generatePath(pages.SHEET.PATH, { appId: sheet.appId, sheetId: sheet.qInfo.qId }),
});

const useGetAppsAndSheets = () => {
  const appsHaveBeenFetched = useRef(false);
  const [appMenuList, setAppMenuList] = useState<SheetListItem[]>([]);
  const sheets = useAppSelector(selectSheetEntities);
  const fetchSheets = useFetchSheets();
  const { apps } = useLazyGetApps({
    loadIfNotInitialized: true,
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.SET_ALL,
  });

  const getItemsFromSheets = useCallback(
    (sheetIds: string[]) => {
      const sheetList = sheetIds.reduce<Items>((sheetItems, id) => {
        const sheet = sheets[id];

        return sheet ? [...sheetItems, mapSheetToItem(sheet)] : sheetItems;
      }, []);

      return sheetList.sort((sheet1, sheet2) => sheet1.rank - sheet2.rank);
    },
    [sheets],
  );

  const mapAppToListItem = useCallback(
    (app: RequireSelected<App, "sheets">): SheetListItem => ({
      id: app.id,
      title: app.qTitle,
      items: getItemsFromSheets(app.sheets.ids),
    }),
    [getItemsFromSheets],
  );

  const fetchSheetsForApps = useCallback(async () => {
    appsHaveBeenFetched.current = true;
    await Promise.all(
      apps.map(app => fetchSheets({ appId: app.id, fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.SET_ALL })),
    );
  }, [apps, fetchSheets]);

  const buildAppMenuList = useCallback(() => {
    const menuList = apps.reduce<SheetListItem[]>((menuList, app) => {
      return app.sheets ? [...menuList, mapAppToListItem(app as RequireSelected<App, "sheets">)] : menuList;
    }, []);

    setAppMenuList(menuList);
  }, [apps, mapAppToListItem]);

  useEffect(() => {
    if (!appMenuList.length && appsHaveBeenFetched.current) {
      appsHaveBeenFetched.current = false;
    }
  }, [appMenuList]);

  useEffect(() => {
    if (apps.length && !appsHaveBeenFetched.current) fetchSheetsForApps();
  }, [apps, appMenuList, appsHaveBeenFetched, fetchSheetsForApps]);

  useEffect(() => {
    if (Object.keys(sheets).length > 0) {
      buildAppMenuList();
    }
  }, [buildAppMenuList, sheets]);

  return {
    apps: appMenuList,
  };
};

export default useGetAppsAndSheets;
