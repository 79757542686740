import PropTypes from "prop-types";

import { THEME } from "@myloc/myloc-gui";
import { Button } from "@myloc/myloc-gui";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { ChevronDown, ChevronUp } from "../../../assets/icons";
import OnClickOutside from "../../../utils/OnClickOutside";
import Collapse from "../../Collapse/Collapse";
import { ListItem, Item } from "./dataTypes";

import styles from "./MenuContainer.module.scss";
import MenuContainerRow from "./MenuContainerRow/MenuContainerRow";
import useDebounce from "../../../utils/useDebounce";
import { useTranslate } from "../../../language/i18n";
import { Search } from "@myloc/myloc-gui";

const filterItems = (items: Item[], searchValue: string | undefined) => {
  if (!searchValue) return items;

  const lowerCaseSearchValue = searchValue.toLowerCase();

  return items.filter(item => item.title.toLowerCase().includes(lowerCaseSearchValue));
};

const filterListItems = (list: ListItem[], searchValue: string | undefined) => {
  if (!searchValue) return list;

  return list.reduce<ListItem[]>((list, listItem) => {
    return [...list, { ...listItem, items: filterItems(listItem.items, searchValue) }];
  }, []);
};

const areListItemsEmpty = (list: ListItem[]) => {
  for (const listItem of list) {
    if (listItem.items.length) return false;
  }

  return true;
};

//Flytta till GUI när det är omgjort till typescript
const MenuContainer = ({
  list,
  title,
  handleOnClickMenuItem,
  activeId,
  showSearch = false,
  customCssClass,
}: {
  list: ListItem[];
  title: string;
  handleOnClickMenuItem: (id: string) => void;
  activeId?: string;
  showSearch?: boolean;
  customCssClass?: string;
}) => {
  const translate = useTranslate();
  const [menuVisible, setMenuVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>();
  const debouncedSearchValue = useDebounce(searchValue);

  const handleOnClick = (id: string) => {
    setMenuVisible(false);
    handleOnClickMenuItem(id);
  };

  useEffect(() => {
    if (!menuVisible && showSearch) setSearchValue("");
  }, [menuVisible, showSearch]);

  const onClickOutside = () => {
    setMenuVisible(false);

    if (showSearch) setSearchValue("");
  };

  const listItems = useMemo(() => filterListItems(list, debouncedSearchValue), [list, debouncedSearchValue]);

  return (
    <OnClickOutside call={() => onClickOutside()}>
      <section className={classNames(styles.menuWrapper, customCssClass)}>
        <Button
          theme={THEME.PLAIN}
          customCssClass={styles.title}
          onClick={() => setMenuVisible(setMenuVisible => !setMenuVisible)}
        >
          {title}
          {menuVisible ? <ChevronUp customCssClass={styles.icon} /> : <ChevronDown customCssClass={styles.icon} />}
        </Button>
        <Collapse visible={menuVisible} customCssClass={styles.submenuWrapper}>
          <div className={styles.menu}>
            {showSearch && (
              <div className={styles.searchBar}>
                <div className={styles.searchIcon}>
                  <Search />
                </div>
                <input
                  value={searchValue}
                  className={classNames(styles.input)}
                  placeholder={translate("SEARCH")}
                  onChange={e => setSearchValue(e.target.value)}
                />
              </div>
            )}
            <ul className={styles.list}>
              {!areListItemsEmpty(listItems) ? (
                listItems.map(listItem => (
                  <MenuContainerRow
                    key={listItem.id}
                    listItem={listItem}
                    activeId={activeId}
                    handleOnClickMenuItem={handleOnClick}
                  />
                ))
              ) : (
                <p className={styles.noResults}>{translate("NO_RESULTS_FOUND")}</p>
              )}
            </ul>
          </div>
        </Collapse>
      </section>
    </OnClickOutside>
  );
};

MenuContainer.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  handleOnClickMenuItem: PropTypes.func.isRequired,
  activeId: PropTypes.string,
  showSearch: PropTypes.bool,
  customCssClass: PropTypes.string,
};

export default MenuContainer;
