const shouldResetRefsOnEmptyState = (resetRefsOnEmptyState: { [id: string]: boolean }) => {
  let shouldResetOnEmptyState = true;

  for (const resetOnEmptyState of Object.values(resetRefsOnEmptyState)) {
    if (!resetOnEmptyState) {
      shouldResetOnEmptyState = false;
      break;
    }
  }

  return shouldResetOnEmptyState;
};

export default shouldResetRefsOnEmptyState;
