import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RequestState, REQUEST_STATE } from "../../dataTypes";
import { useAppSelector } from "../../hooks";
import { fetchCookie } from "../reducers/fetchCookie";
import { selectRequestState } from "../sessionSelectors";

let requestStateRef: RequestState | undefined;

const useFetchCookie = () => {
  const dispatch = useDispatch();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadCookie = useCallback(
    async ({ JWT }: { JWT: string }) => {
      if (requestStateRef !== REQUEST_STATE.PENDING && JWT) {
        requestStateRef = REQUEST_STATE.PENDING;
        dispatch(fetchCookie({ JWT }));
      }
    },
    [dispatch],
  );

  return {
    loadCookie,
  };
};

export default useFetchCookie;
