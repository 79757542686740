/* File has been copied */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dialogSliceName, DialogState, Message, SEVERITY } from "./dataTypes";

const initialState: DialogState = {};

const slice = createSlice({
  name: dialogSliceName,
  initialState,
  reducers: {
    resetMessage(state) {
      state.message = undefined;
    },
    setError(state, action: PayloadAction<Omit<Message, "type">>) {
      state.message = { ...action.payload, type: SEVERITY.ERROR };
    },
    setInfo(state, action: PayloadAction<Omit<Message, "type">>) {
      state.message = { ...action.payload, type: SEVERITY.INFO };
    },
    setWarning(state, action: PayloadAction<Omit<Message, "type">>) {
      state.message = { ...action.payload, type: SEVERITY.WARNING };
    },
  },
});

const { actions, reducer } = slice;

export const { resetMessage, setError, setInfo, setWarning } = actions;

export default reducer;
