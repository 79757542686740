import { useCallback, useEffect, useRef } from "react";

import { FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import { useAppSelector } from "../../hooks";
import { selectApps, selectErrorMessage, selectRequestState } from "../appSelectors";
import useFetchApps from "./useFetchApps";

const useLazyGetApps = ({
  loadIfNotInitialized = false,
  fulfilledUpdateMethod,
}: {
  loadIfNotInitialized?: boolean;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
}) => {
  const fetchApps = useFetchApps();
  const hasInitalized = useRef(false);

  const requestStatus = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);
  const apps = useAppSelector(selectApps);

  const isUninitialized = requestStatus === undefined;
  const isLoading = requestStatus === REQUEST_STATE.PENDING;
  const isError = requestStatus === REQUEST_STATE.REJECTED;
  const isSuccess = requestStatus === REQUEST_STATE.FULFILLED;

  const loadApps = useCallback(async () => {
    await fetchApps({ fulfilledUpdateMethod });
  }, [fetchApps, fulfilledUpdateMethod]);

  useEffect(() => {
    hasInitalized.current = false;
  }, []);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadApps();
    }
  }, [isUninitialized, loadIfNotInitialized, loadApps]);

  return {
    apps,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchApps: loadApps,
  };
};

export default useLazyGetApps;
